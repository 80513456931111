import React from "react";

export default function Notice({ type, message, size }) {
  const validTypes = ["info", "warning", "success", "danger"];
  const validSizes = ["sm", "lg"];

  //default Notice
  let classString = "notice notice-info text-center mt-5 mb-5";
  let sizeString = "";

  if (validTypes.indexOf(type) !== -1) {
    classString = `notice notice-${type} text-center mt-5 mb-5`;
  }

  if (validSizes.indexOf(size) !== -1) {
    sizeString = `notice-${size}`;
  }

  classString += sizeString;

  const messagePieces = message.split("\n");

  return (
    <div id="notice" className={classString}>
      {messagePieces.map((messagePiece, index) => (
        <p key={index}>{messagePiece}</p>
      ))}
    </div>
  );
}
