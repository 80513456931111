import { useContext, useRef } from "react";
import { GlobalContext } from "../../state/contexts/globalState";
import closeModals from "../../util/closeModals";
import scrollToTop from "../../util/scrollToTop";

export const usePageTransition = (scrollTop = true, hideModals = true) => {
  const { setPageTransition } = useContext(GlobalContext);
  const transitionTimeout = useRef(null);

  const startTransition = () => {
    clearTimeout(transitionTimeout.current);

    setPageTransition({
      isPageTransitioning: true,
    });

    if (scrollTop) {
      scrollToTop();
    }

    if (hideModals) {
      closeModals();
    }
  };

  const completeTransition = () => {
    const defaultDuration = 500;

    transitionTimeout.current = setTimeout(() => {
      setPageTransition({
        isPageTransitioning: false,
      });
    }, defaultDuration);
  };

  return { startTransition, completeTransition };
};
