import Joi from "joi";

const schema = Joi.object({
  email: Joi.alternatives()
    .conditional("email", {
      switch: [
        {
          is: Joi.number(),
          then: Joi.number().required(),
          otherwise: Joi.string()
            .email({ tlds: { allow: false } })
            .required(),
        },
      ],
    })
    .required(),
  password: Joi.string().required(),
});

export default schema;
