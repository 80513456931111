import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "../services/validations/resolvers/joiResolver";
import schema from "../services/validations/loginSchema";
import Notice from "./Notice";
import { useRemember } from "../services/hooks/useRemember";

export default function LoginForm({ onLogin, loginError, loginStatus }) {
  const { rememberLocalValue, retrieveLocalValue, removeLocalValue } =
    useRemember();

  const defaultEmail = retrieveLocalValue("emailAddress") || "";
  const [rememberMe, setRememberMe] = useState(defaultEmail ? true : false);

  const { handleSubmit, register, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      email: defaultEmail,
    },
    resolver: joiResolver(schema),
  });

  const onSubmit = (data) => {
    if (rememberMe) {
      rememberLocalValue({
        key: "emailAddress",
        value: data.email,
      });
    } else {
      removeLocalValue("emailAddress");
    }

    onLogin(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center">
        <div className="mb-5">
          <h1 className="display-4">Sign in</h1>
        </div>
        {loginError && loginStatus !== "pending" && (
          <Notice type="danger" message={loginError} />
        )}
      </div>

      <div className="js-form-message form-group">
        <label className="input-label" htmlFor="signinSrEmail">
          Your email
        </label>

        <input
          ref={register}
          type="email"
          className={`form-control form-control-lg ${
            errors.email ? "is-invalid" : ""
          }`}
          name="email"
          id="signinSrEmail"
          tabIndex="1"
          placeholder="Email or Phone Number"
          aria-label="Email or Phone Number"
        />

        {errors.email && (
          <span className="input-error">
            You entered an invalid email address or phone number
          </span>
        )}
      </div>

      <div className="js-form-message form-group">
        <label className="input-label" htmlFor="signinSrPassword" tabIndex="0">
          <span className="d-flex justify-content-between align-items-center">
            Password
            <a
              className="input-label-secondary"
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              Forgot Password?
            </a>
          </span>
        </label>

        <div className="input-group input-group-merge">
          <input
            ref={register}
            type="password"
            className={`js-toggle-password form-control form-control-lg ${
              errors.password ? "is-invalid" : ""
            }`}
            name="password"
            id="signinSrPassword"
            placeholder="Password"
            aria-label="Password"
          />
        </div>

        {errors.password && (
          <span className="input-error">Please enter a password</span>
        )}
      </div>

      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="termsCheckbox"
            name="termsCheckbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label
            className="custom-control-label text-muted"
            htmlFor="termsCheckbox"
          >
            {" "}
            Remember me
          </label>
        </div>
      </div>

      <button
        type="submit"
        className="btn btn-lg btn-block btn-primary"
        disabled={loginStatus === "pending"}
      >
        {loginStatus === "pending" ? "Please wait..." : "Log In"}
      </button>
    </form>
  );
}
