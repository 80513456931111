import storeInLocalStorage from "../../util/storeInLocalStorage";
import retrieveFromLocalStorage from "../../util/retrieveFromLocalStorage";
import removeFromLocalStorage from "../../util/removeFromLocalStorage";

export const useRemember = () => {
  const rememberLocalValue = (param) => {
    const { key, value, ttl } = param;

    if (ttl) {
      storeInLocalStorage(key, value, ttl);
    } else {
      storeInLocalStorage(key, value);
    }
  };

  const retrieveLocalValue = (key) => {
    return retrieveFromLocalStorage(key);
  };

  const removeLocalValue = (key) => {
    removeFromLocalStorage(key);
  };

  return {
    rememberLocalValue,
    retrieveLocalValue,
    removeLocalValue,
  };
};
