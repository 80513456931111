import React, { useEffect } from "react";

import LoginForm from "../components/LoginForm";
import Loader from "../components/Loader";

import { useAuth } from "../services/hooks/useAuth";
import { usePageTransition } from "../services/hooks/usePageTransition";
import { useRedirect } from "../services/hooks/useRedirect";

export default function Login() {
  const { startTransition, completeTransition } = usePageTransition();
  const { user, authStatus, login, loginError, loginStatus } = useAuth();
  const { redirect } = useRedirect();

  useEffect(() => {
    completeTransition();

    return () => {
      startTransition();
    };
  }, []);

  useEffect(() => {
    if (user || loginStatus === "success") {
      redirect("/dashboard");
    }
  }, [user, loginStatus]);

  const onLogin = (data) => {
    login(data);
  };

  if (authStatus !== "ready" || user) {
    return (
      <React.Fragment>
        <Loader absoluteCenter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <main id="content" role="main" className="main">
        <div
          className="position-fixed top-0 right-0 left-0 bg-img-hero"
          style={{
            height: "100vh",
            backgroundImage: "url(/svg/components/abstract-bg-4.svg)",
          }}
        >
          <figure className="position-absolute right-0 bottom-0 left-0">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1921 273"
            >
              <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
            </svg>
          </figure>
        </div>

        <div className="container py-5 py-sm-7">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="card card-lg mb-5">
                <div className="card-body">
                  <LoginForm
                    onLogin={onLogin}
                    loginError={loginError}
                    loginStatus={loginStatus}
                  />
                </div>
              </div>

              <div className="text-center text-white">
                <p>Copyright &copy; 2022 Chic Beauty Inc.</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
